import { PERMISSIONS } from '@aeqoom/permissions'
import CustomDataGrid from '@app/components/base/datagrid/CustomDataGrid'
import CreateTodoListItemDialogContent from '@app/components/base/dialogs/createForms/CreateTodoListItemDialogContent'
import DeleteConfirmDialog from '@app/components/base/dialogs/DeleteConfirmDialog'
import { useDeleteAction } from '@app/hooks/useDeleteAction'
import {
  useDeleteToDoListItem,
  useGetToDoListItems,
} from '@app/queries/useToDoListItems'
import useUserStore from '@app/src/stores/useUserStore'
import getDeleteActionCol from '@app/src/utils/getDeleteActionCol'
import { PlaylistAddOutlined } from '@mui/icons-material'

import todoslistItemsDataCol from './col'

const TodolistItems = () => {
  const { data, isPending } = useGetToDoListItems()

  const mutation = useDeleteToDoListItem()

  const { hasPermission } = useUserStore()

  const {
    handleOpen,
    handleClose,
    handleConfirmDelete,
    isOpen,
    setSelectedItemId,
  } = useDeleteAction(mutation)

  const deleteActionCol = getDeleteActionCol({ handleOpen, setSelectedItemId })

  const columns = [
    ...todoslistItemsDataCol,
    ...(hasPermission(PERMISSIONS.TO_DO_LIST_ITEM.DELETE)
      ? [deleteActionCol]
      : []),
  ]

  return (
    <>
      <DeleteConfirmDialog
        isPending={mutation.isPending}
        onClose={handleClose}
        onConfirm={handleConfirmDelete}
        isOpen={isOpen}
      />
      <CustomDataGrid
        addClickOptions={{
          Dialog: CreateTodoListItemDialogContent,
          title: 'Create TODO list item',
          options: {
            icon: PlaylistAddOutlined,
            isUnique: false,
          },
        }}
        pageLabel="Todolist items"
        loading={isPending}
        rows={data || []}
        columns={columns}
      />
    </>
  )
}

export default TodolistItems
