import { PERMISSIONS } from '@aeqoom/permissions'
import { createUniqueKey } from '@aeqoom/utils'
import CustomDataGrid from '@app/components/base/datagrid/CustomDataGrid'
import ComponentTemplateDialog from '@app/components/base/dialogs/_detailViewDialogs/ComponentTemplateDialog'
import CreateComponentTemplateDialogContent from '@app/components/base/dialogs/createForms/CreateComponentTemplateDialogContent'
import DeleteConfirmDialog from '@app/components/base/dialogs/DeleteConfirmDialog'
import { useDeleteAction } from '@app/hooks/useDeleteAction'
import {
  useDeleteComponentTemplate,
  useGetComponentTemplates,
} from '@app/queries/useComponentTemplates'
import useUserStore from '@app/src/stores/useUserStore'
import getDeleteActionCol from '@app/src/utils/getDeleteActionCol'
import { useDialogStore } from '@app/stores/useDialogStore'
import { GridViewOutlined } from '@mui/icons-material'

import componentTemplatesDataCol from './col'

const ComponentTemplates = () => {
  const { data, isPending } = useGetComponentTemplates()
  const mutation = useDeleteComponentTemplate()

  const {
    handleOpen,
    handleClose,
    handleConfirmDelete,
    isOpen,
    setSelectedItemId,
  } = useDeleteAction(mutation)

  const { openDialog } = useDialogStore()
  const { hasPermission } = useUserStore()

  const deleteActionCol = getDeleteActionCol({ handleOpen, setSelectedItemId })

  const columns = [
    ...componentTemplatesDataCol,
    ...(hasPermission(PERMISSIONS.COMPONENT_TEMPLATE.DELETE)
      ? [deleteActionCol]
      : []),
  ]

  return (
    <>
      <DeleteConfirmDialog
        isPending={mutation.isPending}
        onClose={handleClose}
        onConfirm={handleConfirmDelete}
        isOpen={isOpen}
      />
      <CustomDataGrid
        addClickOptions={{
          Dialog: CreateComponentTemplateDialogContent,
          title: 'Create component template',
          options: {
            icon: GridViewOutlined,
            isUnique: false,
          },
        }}
        onRowClick={({ row }) => {
          openDialog(<ComponentTemplateDialog id={row.id} />, row.name, {
            uniqueKey: createUniqueKey(['componentTemplate', row.id]),
          })
        }}
        pageLabel="Component templates"
        rows={data || []}
        loading={isPending}
        columns={columns}
      />
    </>
  )
}

export default ComponentTemplates
