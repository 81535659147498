import {
  companyUnits,
  media,
  serviceLevelAgreements,
  tasks,
  taskStatus,
  taskType,
  toDoListItemOnTask,
  toDoListItems,
  toDoListOnTask,
  toDoLists,
  users,
  workProtocols,
} from '@aeqoom/db'
import { stringifiedBoolean, z, zodDate } from '@aeqoom/zod'
import {
  ClientInferRequest,
  ClientInferResponseBody,
  initContract,
} from '@ts-rest/core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'

import { globalFilter } from '../schemas'

const c = initContract()

const getTasksResponse200 = z.array(
  z.object({
    ...createSelectSchema(tasks).shape,
    user: z
      .object({
        ...createSelectSchema(users).shape,
        avatar: z.string().nullish(),
      })
      .nullable(),
    serviceLevelAgreement: createSelectSchema(
      serviceLevelAgreements
    ).nullable(),
    companyUnit: z
      .object({
        ...createSelectSchema(companyUnits).shape,
        avatar: z.string().nullish(),
      })
      .nullable(),
  })
)

const tasksResponseSchema = z.array(
  z.intersection(
    createSelectSchema(tasks).pick({
      name: true,
      deadline: true,
      type: true,
      priority: true,
      status: true,
      id: true,
    }),

    z.object({
      user: z
        .object({
          ...createSelectSchema(users).pick({
            lastName: true,
            firstName: true,
            id: true,
          }).shape,
          avatar: z.string().nullish(),
        })
        .nullable()
        .optional(),
      serviceLevelAgreement: z.intersection(
        createSelectSchema(serviceLevelAgreements),
        z.object({
          companyUnit: z.object({
            ...createSelectSchema(companyUnits).pick({
              name: true,
            }).shape,
            avatar: z.string().nullish(),
          }),
        })
      ),
    })
  )
)

const tasksRouter = c.router(
  {
    getTasks: {
      method: 'GET',
      path: '/tasks',
      query: z.intersection(
        z.object({
          companyId: z.coerce.number().nullish(),
          companyUnitId: z.coerce.number().nullish(),
          deadlineFrom: z.coerce.date().nullish(),
          deadlineTo: z.coerce.date().nullish(),
          onlyMine: stringifiedBoolean.optional(),
          onlyAssignedToMe: stringifiedBoolean.optional(),
          type: z.array(z.enum(taskType.enumValues)).optional(),
          status: z.array(z.enum(taskStatus.enumValues)).optional(),
          onlyPlanned: stringifiedBoolean.optional(),
          's.priority': z.enum(['asc', 'desc']).optional(),
        }),
        globalFilter
      ),
      responses: {
        200: getTasksResponse200,
      },
    },
    getTask: {
      method: 'GET',
      path: '/tasks/:id/get',
      pathParams: z.object({
        id: z.coerce.number(),
      }),
      responses: {
        200: z.object({
          ...createSelectSchema(tasks).shape,
          user: createSelectSchema(users, {
            avatar: createSelectSchema(media),
          }).nullable(),
          createdBy: createSelectSchema(users, {
            avatar: createSelectSchema(media),
          }).nullable(),
          toDoList: z
            .object({
              ...createSelectSchema(toDoListOnTask).shape,
              toDoListItems: z.array(
                z.object({
                  ...createSelectSchema(toDoListItemOnTask).shape,
                  template: z.object({
                    ...createSelectSchema(toDoListItems).shape,
                    media: z.array(z.number()),
                  }),
                })
              ),
              template: createSelectSchema(toDoLists),
            })
            .nullable(),
          serviceLevelAgreement: z
            .object({
              ...createSelectSchema(serviceLevelAgreements).shape,
              companyUnit: z
                .object({
                  ...createSelectSchema(companyUnits).shape,
                  avatar: createSelectSchema(media).nullable(),
                })
                .nullable(),
            })
            .nullable(),
          qrcode: z.string().nullable(),
          media: z.array(z.number()),
        }),
        404: z.object({ message: z.string() }),
      },
    },
    addTask: {
      method: 'POST',
      path: '/tasks',
      body: z.intersection(
        createInsertSchema(tasks).pick({
          name: true,
          userId: true,
          type: true,
          priority: true,
          description: true,
          serviceLevelAgreementId: true,
          status: true,
          toDoListId: true,
          componentInstanceId: true,
          machineInstanceId: true,
          deadline: true,
        }),
        z.object({
          companyId: z.coerce.number().optional().nullable(),
          companyUnitId: z.coerce.number(),
          media: z.array(z.number()),
        })
      ),
      responses: {
        200: z.object({
          id: z.number(),
          message: z.string(),
        }),
      },
    },
    editTask: {
      method: 'POST',
      path: '/tasks/:id/edit',
      pathParams: z.object({
        id: z.coerce.number(),
      }),
      body: z.object({
        ...createInsertSchema(tasks).omit({
          plannedFrom: true,
          plannedTo: true,
        }).shape,
        media: z.array(z.number()),
      }),
      responses: {
        200: z.object({
          id: z.number(),
          message: z.string(),
        }),
      },
    },
    deleteTask: {
      method: 'DELETE',
      path: '/tasks/:id/delete',
      pathParams: z.object({
        id: z.coerce.number(),
      }),
      body: z.object({}),
      responses: {
        200: z.object({
          id: z.number(),
          message: z.string(),
        }),
      },
    },
    assignUser: {
      method: 'POST',
      path: '/tasks/:id/assign-user',
      pathParams: z.object({
        id: z.coerce.number(),
      }),
      body: z.object({
        userId: z.number(),
      }),
      responses: {
        200: z.object({
          id: z.number(),
          message: z.string(),
        }),
      },
    },
    planTask: {
      method: 'POST',
      path: '/tasks/:id/plan-task',
      pathParams: z.object({
        id: z.coerce.number(),
      }),
      body: z.object({
        plannedFrom: zodDate,
        plannedTo: zodDate,
        allDay: z.boolean().nullable(),
        userId: z.number().nullable(),
      }),
      responses: {
        200: z.object({
          id: z.number(),
          message: z.string(),
        }),
      },
    },
    getPlanOfTask: {
      method: 'GET',
      path: '/tasks/:id/get-plan',
      pathParams: z.object({
        id: z.coerce.number(),
      }),
      responses: {
        200: z.object({
          plannedFrom: zodDate,
          plannedTo: zodDate,
          allDay: z.boolean().nullish(),
          assignee: z.number().nullish(),
        }),
      },
    },
    changeStatus: {
      method: 'POST',
      path: '/tasks/:id/change-status',
      pathParams: z.object({
        id: z.coerce.number(),
      }),
      body: z.object({
        status: z.enum(taskStatus.enumValues),
      }),
      responses: {
        200: z.object({
          id: z.number(),
          message: z.string(),
        }),
      },
    },
    saveWorkProtocol: {
      method: 'POST',
      path: '/tasks/:id/save-work-protocol',
      pathParams: z.object({
        id: z.coerce.number(),
      }),
      body: createInsertSchema(workProtocols).omit({
        id: true,
        taskId: true,
        status: true,
        userId: true,
      }),
      responses: {
        200: z.object({
          id: z.number(),
          message: z.string(),
        }),
        404: z.object({ message: z.string() }),
        400: z.object({ message: z.string() }),
        401: z.object({ message: z.string() }),
      },
    },
    completeWorkProtocol: {
      method: 'POST',
      path: '/tasks/:id/complete-work-protocol',
      pathParams: z.object({
        id: z.coerce.number(),
      }),
      body: createInsertSchema(workProtocols).omit({
        taskId: true,
        userId: true,
      }),
      responses: {
        200: z.object({
          id: z.number(),
          message: z.string(),
        }),
        404: z.object({ message: z.string() }),
        400: z.object({ message: z.string() }),
        401: z.object({ message: z.string() }),
        409: z.object({ message: z.string() }),
      },
    },
    getWorkProtocol: {
      method: 'GET',
      path: '/tasks/:taskId/work-protocol',
      pathParams: z.object({
        taskId: z.coerce.number(),
      }),
      responses: {
        200: z.object({
          ...createSelectSchema(workProtocols).shape,
        }),
        204: z.undefined(),
      },
    },
    getToDoList: {
      method: 'GET',
      path: '/tasks/:id/get-to-do-list',
      pathParams: z.object({
        id: z.coerce.number(),
      }),
      responses: {
        200: z.object({
          id: z.number(),
          toDoListItems: z.array(
            z.object({
              id: z.number(),
              name: z.string(),
              value: z.number().nullable(),
              content: z.string().nullable(),
              media: z.array(z.number()),
              order: z.number(),
            })
          ),
        }),
      },
    },
  },
  { strictStatusCodes: true }
)

export type CompleteWorkProtocolRequest = ClientInferRequest<
  typeof tasksRouter.completeWorkProtocol
>

export type SaveWorkProtocolRequest = ClientInferRequest<
  typeof tasksRouter.saveWorkProtocol
>

export type ChangeStatusRequest = ClientInferRequest<
  typeof tasksRouter.changeStatus
>

export type AssignUserRequest = ClientInferRequest<
  typeof tasksRouter.assignUser
>

export type GetTaskResponseBody = ClientInferResponseBody<
  typeof tasksRouter.getTask,
  200
>

export type PlanTaskRequest = ClientInferRequest<typeof tasksRouter.planTask>

export type GetTasksResponseBody = ClientInferResponseBody<
  typeof tasksRouter.getTasks
>

export type Flatten<Type> = Type extends Array<infer Item> ? Item : Type

export type AddTaskRequest = ClientInferRequest<typeof tasksRouter.addTask>

export type EditTaskRequest = ClientInferRequest<typeof tasksRouter.editTask>

export type DeleteTaskRequest = ClientInferRequest<
  typeof tasksRouter.deleteTask
>

export type TaskResponse = z.infer<typeof getTasksResponse200>

export default tasksRouter
