import { useGetTranslations } from '@aeqoom/hooks'
import { PERMISSIONS } from '@aeqoom/permissions'
import { createUniqueKey } from '@aeqoom/utils'
import CustomDataGrid from '@app/components/base/datagrid/CustomDataGrid'
import CompanyDialog from '@app/components/base/dialogs/_detailViewDialogs/CompanyDialog'
import CreateCompanyDialogContent from '@app/components/base/dialogs/createForms/CreateCompanyDialogContent'
import DeleteConfirmDialog from '@app/components/base/dialogs/DeleteConfirmDialog'
import { useDeleteAction } from '@app/hooks/useDeleteAction'
import companiesDataCol from '@app/pages/DatagridPages/Companies/col'
import { useDeleteCompany, useGetCompanies } from '@app/queries/useCompanies'
import useUserStore from '@app/src/stores/useUserStore'
import getDeleteActionCol from '@app/src/utils/getDeleteActionCol'
import { useDialogStore } from '@app/stores/useDialogStore'
import { GroupAdd } from '@mui/icons-material'

const CompaniesTable = () => {
  const { data, isPending } = useGetCompanies()
  const { companyTypeTranslation } = useGetTranslations()

  const mutation = useDeleteCompany()
  const { openDialog } = useDialogStore()
  const { hasPermission } = useUserStore()

  const {
    handleOpen,
    handleClose,
    handleConfirmDelete,
    isOpen,
    setSelectedItemId,
  } = useDeleteAction(mutation)

  const deleteActionCol = getDeleteActionCol({ handleOpen, setSelectedItemId })

  const columns = [
    ...companiesDataCol(companyTypeTranslation),
    ...(hasPermission(PERMISSIONS.COMPANY.DELETE)
      ? [deleteActionCol]
      : []),
  ]

  return (
    <>
      <DeleteConfirmDialog
        isPending={mutation.isPending}
        onClose={handleClose}
        onConfirm={handleConfirmDelete}
        isOpen={isOpen}
      />
      <CustomDataGrid
        pageLabel="Companies table"
        rows={data || []}
        columns={columns}
        loading={isPending}
        addClickOptions={{
          Dialog: CreateCompanyDialogContent,
          title: 'Create new company',
          options: {
            icon: GroupAdd,
            isUnique: false,
          },
        }}
        onRowClick={({ row }) => {
          openDialog(<CompanyDialog id={row.id} />, row.name, {
            uniqueKey: createUniqueKey(['company', row.id]),
          })
        }}
      />
    </>
  )
}

export default CompaniesTable
