import CustomDataGrid from '@app/components/base/datagrid/CustomDataGrid'
import CreateTaskTemplateDialogContent from '@app/components/base/dialogs/createForms/CreateTaskTemplateDialogContent'
import DeleteConfirmDialog from '@app/components/base/dialogs/DeleteConfirmDialog'
import { useDeleteAction } from '@app/hooks/useDeleteAction'
import {
  useDeleteTaskTemplate,
  useGetTaskTemplates,
} from '@app/queries/useTaskTemplates'
import getDeleteActionCol from '@app/src/utils/getDeleteActionCol'
import { PostAddOutlined } from '@mui/icons-material'

import tasksDataCol from './col'

const TaskTemplates = () => {
  const { data, isPending } = useGetTaskTemplates()
  const mutation = useDeleteTaskTemplate()

  const {
    handleOpen,
    handleClose,
    handleConfirmDelete,
    isOpen,
    setSelectedItemId,
  } = useDeleteAction(mutation)

  const deleteActionCol = getDeleteActionCol({ handleOpen, setSelectedItemId })

  return (
    <>
      <DeleteConfirmDialog
        isPending={mutation.isPending}
        onClose={handleClose}
        onConfirm={handleConfirmDelete}
        isOpen={isOpen}
      />

      <CustomDataGrid
        addClickOptions={{
          Dialog: CreateTaskTemplateDialogContent,
          title: 'Create task template',
          options: {
            icon: PostAddOutlined,
            isUnique: false,
          },
        }}
        pageLabel="Task templates"
        loading={isPending}
        rows={data || []}
        columns={[...tasksDataCol, deleteActionCol]}
      />
    </>
  )
}

export default TaskTemplates
