import { PERMISSIONS } from '@aeqoom/permissions'
import { createUniqueKey } from '@aeqoom/utils'
import CustomDataGrid from '@app/components/base/datagrid/CustomDataGrid'
import MachineTemplateDialog from '@app/components/base/dialogs/_detailViewDialogs/MachineTemplateDialog'
import CreateMachineTemplateDialogContent from '@app/components/base/dialogs/createForms/CreateMachineTemplateDialogContent'
import DeleteConfirmDialog from '@app/components/base/dialogs/DeleteConfirmDialog'
import { useDeleteAction } from '@app/hooks/useDeleteAction'
import {
  useDeleteMachineTemplate,
  useGetMachineTemplates,
} from '@app/queries/useMachineTemplates'
import useUserStore from '@app/src/stores/useUserStore'
import getDeleteActionCol from '@app/src/utils/getDeleteActionCol'
import { useDialogStore } from '@app/stores/useDialogStore'
import { GridOnOutlined } from '@mui/icons-material'

import machineTemplatesDataCol from './col'

const MachineTemplates = () => {
  const { data, isPending } = useGetMachineTemplates()
  const mutation = useDeleteMachineTemplate()

  const {
    handleOpen,
    handleClose,
    handleConfirmDelete,
    isOpen,
    setSelectedItemId,
  } = useDeleteAction(mutation)

  const { openDialog } = useDialogStore()
  const { hasPermission } = useUserStore()

  const deleteActionCol = getDeleteActionCol({ handleOpen, setSelectedItemId })

  const columns = [
    ...machineTemplatesDataCol,
    ...(hasPermission(PERMISSIONS.MACHINE_TEMPLATE.DELETE)
      ? [deleteActionCol]
      : []),
  ]

  return (
    <>
      <DeleteConfirmDialog
        isPending={mutation.isPending}
        onClose={handleClose}
        onConfirm={handleConfirmDelete}
        isOpen={isOpen}
      />

      <CustomDataGrid
        addClickOptions={{
          Dialog: CreateMachineTemplateDialogContent,
          title: 'Create machine template',
          options: {
            icon: GridOnOutlined,
            isUnique: false,
          },
        }}
        onRowClick={({ row }) => {
          openDialog(<MachineTemplateDialog id={row.id} />, row.name, {
            uniqueKey: createUniqueKey(['machineTemplate', row.id]),
          })
        }}
        loading={isPending}
        pageLabel="Machine templates"
        rows={data || []}
        columns={columns}
      />
    </>
  )
}

export default MachineTemplates
