import { PERMISSIONS } from '@aeqoom/permissions'
import CreateSLADialogContent from '@app/components/base/dialogs/createForms/CreateSLADialogContent'
import CheckPermissions from '@app/components/CheckPermissions'
import ErrorScreen from '@app/components/ErrorScreen'
import { useGetSla } from '@app/queries/useServiceLevelAgreement'
import { useDialogStore } from '@app/stores/useDialogStore'
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Stack,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import Content from './Content'

type Props = {
  id?: number
}

const ContractDialog = ({ id }: Props) => {
  const { t } = useTranslation('web', {
    keyPrefix: 'contracts.detail',
  })

  const { isPending, isError, refetch, data } = useGetSla({
    id,
  })

  const { openDialog, closeTab } = useDialogStore()

  if (isPending)
    return (
      <DialogContent>
        <Stack alignItems="center" justifyContent="center" height="100%">
          <CircularProgress />
        </Stack>
      </DialogContent>
    )

  if (isError)
    return (
      <DialogContent>
        <Stack alignItems="center" justifyContent="center" height="100%">
          <ErrorScreen refetch={refetch} />
        </Stack>
      </DialogContent>
    )

  return (
    <>
      <Content data={data} />
      <CheckPermissions permissions={[PERMISSIONS.COMPANY.UPDATE]}>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              openDialog(
                <CreateSLADialogContent
                  isEditModal
                  id={id}
                  callback={() => {
                    closeTab()
                  }}
                />,
                'Edit contract'
              )
            }}
          >
            {t('actions.edit')}
          </Button>
        </DialogActions>
      </CheckPermissions>
    </>
  )
}

export default ContractDialog
