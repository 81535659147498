import { PERMISSIONS } from '@aeqoom/permissions'
import { TaskPriority, TaskStatus, TaskType } from '@aeqoom/shared'
import {
  ColumnDirection,
  ColumnVariant,
} from '@app/components/BacklogColumn/types'
import TaskItem from '@app/components/TaskItem'
import { useGetBacklog } from '@app/queries/useBacklog'
import { useDialogStore } from '@app/stores/useDialogStore'
import useUserStore from '@app/stores/useUserStore'
import {
  CheckCircleOutline,
  EventAvailable,
  PlaylistAdd,
  WarningAmber,
} from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CardContent,
  Skeleton,
  Stack,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import CreateTaskDialogContent from '../base/dialogs/createForms/CreateTaskDialogContent'
import { StyledColumn } from './styled'

type Props = {
  name?: string
  direction?: ColumnDirection
  variant: ColumnVariant
}

type Column = Record<
  ColumnVariant,
  {
    icon: ReactElement
    title: string
    type: TaskType[]
    status: TaskStatus[]
    priority: TaskPriority[]
  }
>

const COLUMNS: Column = {
  [ColumnVariant.HIGH_PRIORITY]: {
    icon: <WarningAmber />,
    title: 'High priority',
    type: [],
    status: ['new', 'acknowledged', 'recurred'],
    priority: ['high'],
  },
  [ColumnVariant.TO_BE_DONE]: {
    icon: <PlaylistAdd />,
    title: 'To be done',
    type: [],
    status: ['new', 'acknowledged', 'recurred'],
    priority: ['medium', 'low'],
  },
  [ColumnVariant.PLANNED]: {
    icon: <EventAvailable />,
    title: 'Planned',
    type: [],
    status: ['in_progress', 'waiting_on_wp', 'planned', 'recurred'],
    priority: [],
  },
  [ColumnVariant.TO_ACCEPT]: {
    icon: <CheckCircleOutline />,
    title: 'To accept',
    type: [],
    status: ['to_be_checked_by_admin'],
    priority: [],
  },
}

const BacklogColumn = ({
  direction = ColumnDirection.COLUMN,
  variant = ColumnVariant.HIGH_PRIORITY,
}: Props) => {
  const { openDialog } = useDialogStore()

  const { t } = useTranslation()

  const column = COLUMNS[variant]

  const { activeCompany, activeCompanyUnit, user, hasPermission } =
    useUserStore()

  const { data, isPending, isError } = useGetBacklog({
    companyId: activeCompany,
    companyUnitId: activeCompanyUnit,
    type: column.type,
    status: column.status,
    priority: column.priority,
    assigneeId: !hasPermission(PERMISSIONS.TASK.ASSIGN_USER)
      ? user?.id || undefined
      : undefined,
  })

  if (isError)
    return (
      <Alert color="error">
        <AlertTitle>{t('backlog.columnFailedToLoad.title')}</AlertTitle>
        {t('backlog.columnFailedToLoad.description')}
      </Alert>
    )

  const Content = () => {
    if (isPending)
      return [...Array(1)].map(() => (
        <Skeleton
          variant="rounded"
          height={282}
          width="100%"
          sx={{
            maxWidth: direction === ColumnDirection.ROW ? 350 : '100%',
            flexShrink: 0,
          }}
        />
      ))

    return data.tasks.map((item) => (
      <TaskItem
        task={item}
        direction={direction}
        key={item.id}
        variant={variant}
      />
    ))
  }

  return (
    <StyledColumn type={variant}>
      <CardContent>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginBottom: 2 }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            {column.icon}
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography>{column.title}</Typography>
              <Typography>
                {isPending ? (
                  <Skeleton width={24} />
                ) : (
                  <Box>({data.count})</Box>
                )}
              </Typography>
            </Stack>
          </Stack>
          {variant === ColumnVariant.TO_BE_DONE && (
            <Button
              variant="contained"
              color="secondary"
              startIcon={<AddIcon />}
              onClick={() =>
                openDialog(<CreateTaskDialogContent />, 'Create task', {
                  isUnique: false,
                })
              }
            >
              {t('backlog.addTask')}
            </Button>
          )}
        </Stack>
        <Stack
          direction={direction === ColumnDirection.ROW ? 'row' : 'column'}
          spacing={2}
          sx={{
            overflowX: direction === ColumnDirection.ROW ? 'auto' : 'hidden',
          }}
        >
          <Content />
        </Stack>
      </CardContent>
    </StyledColumn>
  )
}

export default BacklogColumn
