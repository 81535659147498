import { PERMISSIONS } from '@aeqoom/permissions'
import { createUniqueKey } from '@aeqoom/utils'
import CustomDataGrid from '@app/components/base/datagrid/CustomDataGrid'
import CompanyUnitDialog from '@app/components/base/dialogs/_detailViewDialogs/CompanyUnitDialog'
import CreateCompanyUnitDialogContent from '@app/components/base/dialogs/createForms/CreateCompanyUnitDialogContent'
import DeleteConfirmDialog from '@app/components/base/dialogs/DeleteConfirmDialog'
import { useDeleteAction } from '@app/hooks/useDeleteAction'
import {
  useDeleteCompanyUnit,
  useGetCompanyUnits,
} from '@app/queries/useCompanyUnits'
import useUserStore from '@app/src/stores/useUserStore'
import getDeleteActionCol from '@app/src/utils/getDeleteActionCol'
import { useDialogStore } from '@app/stores/useDialogStore'
import { AddBusiness } from '@mui/icons-material'

import companyUnitsDataCol from './col'

const CompaniesUnits = () => {
  const { data, isPending } = useGetCompanyUnits()

  const mutation = useDeleteCompanyUnit()
  const { openDialog } = useDialogStore()
  const { hasPermission } = useUserStore()

  const {
    handleOpen,
    handleClose,
    handleConfirmDelete,
    isOpen,
    setSelectedItemId,
  } = useDeleteAction(mutation)

  const deleteActionCol = getDeleteActionCol({ handleOpen, setSelectedItemId })

  const columns = [
    ...companyUnitsDataCol,
    ...(hasPermission(PERMISSIONS.COMPANY_UNIT.DELETE)
      ? [deleteActionCol]
      : []),
  ]

  return (
    <>
      <DeleteConfirmDialog
        isPending={mutation.isPending}
        onClose={handleClose}
        onConfirm={handleConfirmDelete}
        isOpen={isOpen}
      />

      <CustomDataGrid
        rows={data || []}
        columns={columns}
        loading={isPending}
        addClickOptions={{
          Dialog: CreateCompanyUnitDialogContent,
          title: 'Create company unit',
          options: {
            icon: AddBusiness,
            isUnique: false,
          },
        }}
        onRowClick={({ row }) => {
          openDialog(<CompanyUnitDialog id={row.id} />, row.name, {
            uniqueKey: createUniqueKey(['companyUnit', row.id]),
          })
        }}
        pageLabel="Company units"
      />
    </>
  )
}

export default CompaniesUnits
