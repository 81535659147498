import { Button, Stack, Typography } from '@mui/material'
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query'

const ErrorScreen = ({
  refetch,
}: {
  refetch: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<unknown, Error>>
}) => {
  return (
    <Stack alignItems="center">
      <Typography variant="body1Medium">Failed to load data.</Typography>
      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: 20 }}
        onClick={() => refetch()}
        sx={{ width: 200 }}
      >
        Try Again
      </Button>
    </Stack>
  )
}

export default ErrorScreen
