import { media, User } from '@aeqoom/db'
import { AppError, HttpStatusCode, UserGroups } from '@aeqoom/shared'
import { and, eq, or, SQL } from 'drizzle-orm'
import { PgColumn } from 'drizzle-orm/pg-core'

export const PERMISSIONS = {
  ALL: {
    ANY: '*',
    READ: '*.read',
    LIST: '*.list',
    CREATE: '*.create',
    UPDATE: '*.update',
    DELETE: '*.delete',
  },
  BACKLOG: {
    ANY: 'backlog.*',
    LIST: 'backlog.list',
  },
  COMPANY: {
    ANY: 'company.*',
    READ: 'company.read',
    LIST: 'company.list',
    CREATE: 'company.create',
    UPDATE: 'company.update',
    DELETE: 'company.delete',
  },
  COMPANY_UNIT: {
    ANY: 'company-unit.*',
    READ: 'company-unit.read',
    LIST: 'company-unit.list',
    CREATE: 'company-unit.create',
    UPDATE: 'company-unit.update',
    DELETE: 'company-unit.delete',
  },
  COMPONENT_INSTANCE: {
    ANY: 'component-instance.*',
    READ: 'component-instance.read',
    LIST: 'component-instance.list',
    CREATE: 'component-instance.create',
    UPDATE: 'component-instance.update',
    DELETE: 'component-instance.delete',
  },
  COMPONENT_TEMPLATE: {
    ANY: 'component-template.*',
    READ: 'component-template.read',
    LIST: 'component-template.list',
    CREATE: 'component-template.create',
    UPDATE: 'component-template.update',
    DELETE: 'component-template.delete',
  },
  DASHBOARD: {
    ANY: 'dashboard.*',
    READ: 'dashboard.read',
    LIST: 'dashboard.list',
  },
  MACHINE_INSTANCE: {
    ANY: 'machine-instance.*',
    READ: 'machine-instance.read',
    LIST: 'machine-instance.list',
    CREATE: 'machine-instance.create',
    UPDATE: 'machine-instance.update',
    DELETE: 'machine-instance.delete',
  },
  SERVICE_LOG: {
    ANY: 'service-log.*',
    READ: 'service-log.read',
    LIST: 'service-log.list',
    CREATE: 'service-log.create',
    UPDATE: 'service-log.update',
    DELETE: 'service-log.delete',
  },
  MACHINE_TEMPLATE: {
    ANY: 'machine-template.*',
    READ: 'machine-template.read',
    LIST: 'machine-template.list',
    CREATE: 'machine-template.create',
    UPDATE: 'machine-template.update',
    DELETE: 'machine-template.delete',
  },
  QRCODE: {
    ANY: 'qrcode.*',
    READ: 'qrcode.read',
    LIST: 'qrcode.list',
  },
  MEDIA: {
    ANY: 'media.*',
    READ: 'media.read',
    LIST: 'media.list',
    CREATE: 'media.create',
    UPDATE: 'media.update',
    DELETE: 'media.delete',
  },
  MEDIA_TYPE: {
    ANY: 'media-type.*',
    CONTRACT: 'media-type.contract',
    GENERAL: 'media-type.video',
    AVATAR: 'media-type.avatar',
  },
  MEDIA_VERSION: {
    ANY: 'media-version.*',
    READ: 'media-version.read',
    LIST: 'media-version.list',
    CREATE: 'media-version.create',
    UPDATE: 'media-version.update',
    DELETE: 'media-version.delete',
  },
  SERVICE_LEVEL_AGREEMENT: {
    ANY: 'service-level-agreement.*',
    READ: 'service-level-agreement.read',
    LIST: 'service-level-agreement.list',
    CREATE: 'service-level-agreement.create',
    UPDATE: 'service-level-agreement.update',
    DELETE: 'service-level-agreement.delete',
  },
  TASK: {
    ANY: 'task.*',
    READ: 'task.read',
    LIST: 'task.list',
    CREATE: 'task.create',
    UPDATE: 'task.update',
    DELETE: 'task.delete',
    ASSIGN_USER: 'task.assign-user',
    CHANGE_STATUS: 'task.change-status',
    PLAN: 'task.plan',
  },
  TASK_TEMPLATE: {
    ANY: 'task-template.*',
    READ: 'task-template.read',
    LIST: 'task-template.list',
    CREATE: 'task-template.create',
    UPDATE: 'task-template.update',
    DELETE: 'task-template.delete',
  },
  TO_DO_LIST: {
    ANY: 'to-do-list.*',
    READ: 'to-do-list.read',
    LIST: 'to-do-list.list',
    CREATE: 'to-do-list.create',
    UPDATE: 'to-do-list.update',
    DELETE: 'to-do-list.delete',
  },
  TO_DO_LIST_ITEM: {
    ANY: 'to-do-list-item.*',
    READ: 'to-do-list-item.read',
    LIST: 'to-do-list-item.list',
    CREATE: 'to-do-list-item.create',
    UPDATE: 'to-do-list-item.update',
    DELETE: 'to-do-list-item.delete',
    CHECK_UPDATE: 'to-do-list-item.check-update',
  },
  USER: {
    ANY: 'user.*',
    READ: 'user.read',
    LIST: 'user.list',
    CREATE: 'user.create',
    UPDATE: 'user.update',
    DELETE: 'user.delete',
  },
  WORK_PROTOCOL: {
    ANY: 'work-protocol.*',
    READ: 'work-protocol.read',
    LIST: 'work-protocol.list',
    CREATE: 'work-protocol.create',
    UPDATE: 'work-protocol.update',
    DELETE: 'work-protocol.delete',
  },
  CONTACT: {
    ANY: 'contact.*',
    READ: 'contact.read',
    LIST: 'contact.list',
    CREATE: 'contact.create',
    UPDATE: 'contact.update',
    DELETE: 'contact.delete',
  },
  ENTITY_ACCESS: {
    NO_LIMIT: 'entity-access.no-limit',
    BOTH: 'entity-access.by-both',
    BY_COMPANY: 'entity-access.by-company',
    BY_COMPANY_UNIT: 'entity-access.by-company-unit',
  },
} as const

export const ProviderSuperAdmin = [PERMISSIONS.ALL.ANY]

export const ProviderAdmin = [
  PERMISSIONS.BACKLOG.ANY,
  PERMISSIONS.COMPANY.READ,
  PERMISSIONS.COMPANY.LIST,
  PERMISSIONS.COMPANY_UNIT.READ,
  PERMISSIONS.COMPANY_UNIT.LIST,
  PERMISSIONS.COMPONENT_INSTANCE.READ,
  PERMISSIONS.COMPONENT_INSTANCE.LIST,
  PERMISSIONS.COMPONENT_TEMPLATE.READ,
  PERMISSIONS.COMPONENT_TEMPLATE.LIST,
  PERMISSIONS.DASHBOARD.ANY,
  PERMISSIONS.MACHINE_INSTANCE.READ,
  PERMISSIONS.MACHINE_INSTANCE.LIST,
  PERMISSIONS.MACHINE_TEMPLATE.READ,
  PERMISSIONS.MACHINE_TEMPLATE.LIST,
  PERMISSIONS.QRCODE.ANY,
  PERMISSIONS.MEDIA.ANY,
  PERMISSIONS.MEDIA_TYPE.ANY,
  PERMISSIONS.MEDIA_VERSION.ANY,
  PERMISSIONS.SERVICE_LEVEL_AGREEMENT.READ,
  PERMISSIONS.SERVICE_LEVEL_AGREEMENT.LIST,
  PERMISSIONS.TASK.READ,
  PERMISSIONS.TASK.LIST,
  PERMISSIONS.TASK.CREATE,
  PERMISSIONS.TASK.UPDATE,
  PERMISSIONS.TASK.ASSIGN_USER,
  PERMISSIONS.TASK.CHANGE_STATUS,
  PERMISSIONS.TASK_TEMPLATE.READ,
  PERMISSIONS.TASK.PLAN,
  PERMISSIONS.TO_DO_LIST.READ,
  PERMISSIONS.TO_DO_LIST.LIST,
  PERMISSIONS.TO_DO_LIST.CREATE,
  PERMISSIONS.TO_DO_LIST.UPDATE,
  PERMISSIONS.TO_DO_LIST_ITEM.READ,
  PERMISSIONS.TO_DO_LIST_ITEM.LIST,
  PERMISSIONS.TO_DO_LIST_ITEM.CREATE,
  PERMISSIONS.TO_DO_LIST_ITEM.UPDATE,
  PERMISSIONS.TO_DO_LIST_ITEM.CHECK_UPDATE,
  PERMISSIONS.USER.READ,
  PERMISSIONS.USER.LIST,
  PERMISSIONS.WORK_PROTOCOL.READ,
  PERMISSIONS.WORK_PROTOCOL.LIST,
  PERMISSIONS.WORK_PROTOCOL.UPDATE,
  PERMISSIONS.CONTACT.READ,
  PERMISSIONS.CONTACT.LIST,
  PERMISSIONS.ENTITY_ACCESS.NO_LIMIT,
  PERMISSIONS.SERVICE_LOG.READ,
]

export const ClientSuperAdmin = [
  PERMISSIONS.BACKLOG.ANY,
  PERMISSIONS.COMPANY.READ,
  PERMISSIONS.COMPANY.LIST,
  PERMISSIONS.COMPANY_UNIT.ANY,
  PERMISSIONS.COMPONENT_INSTANCE.READ,
  PERMISSIONS.COMPONENT_INSTANCE.LIST,
  PERMISSIONS.DASHBOARD.ANY,
  PERMISSIONS.MACHINE_INSTANCE.READ,
  PERMISSIONS.MACHINE_INSTANCE.LIST,
  PERMISSIONS.QRCODE.ANY,
  PERMISSIONS.MEDIA.ANY,
  PERMISSIONS.MEDIA_TYPE.ANY,
  PERMISSIONS.MEDIA_VERSION.ANY,
  PERMISSIONS.SERVICE_LEVEL_AGREEMENT.READ,
  PERMISSIONS.SERVICE_LEVEL_AGREEMENT.LIST,
  PERMISSIONS.TASK.READ,
  PERMISSIONS.TASK.LIST,
  PERMISSIONS.TASK.CREATE,
  PERMISSIONS.TASK.ASSIGN_USER,
  PERMISSIONS.TASK.CHANGE_STATUS,
  PERMISSIONS.TASK.PLAN,
  PERMISSIONS.TO_DO_LIST.READ,
  PERMISSIONS.TO_DO_LIST.LIST,
  PERMISSIONS.TO_DO_LIST_ITEM.READ,
  PERMISSIONS.TO_DO_LIST_ITEM.LIST,
  PERMISSIONS.TO_DO_LIST_ITEM.CHECK_UPDATE,
  PERMISSIONS.USER.ANY,
  PERMISSIONS.WORK_PROTOCOL.READ,
  PERMISSIONS.WORK_PROTOCOL.LIST,
  PERMISSIONS.WORK_PROTOCOL.UPDATE,
  PERMISSIONS.CONTACT.READ,
  PERMISSIONS.CONTACT.LIST,
  PERMISSIONS.ENTITY_ACCESS.BOTH,
  PERMISSIONS.SERVICE_LOG.READ,
]

export const ClientUnitAdmin = [
  PERMISSIONS.BACKLOG.ANY,
  PERMISSIONS.COMPANY.READ,
  PERMISSIONS.COMPANY.LIST,
  PERMISSIONS.COMPANY_UNIT.READ,
  PERMISSIONS.COMPANY_UNIT.LIST,
  PERMISSIONS.COMPONENT_INSTANCE.READ,
  PERMISSIONS.COMPONENT_INSTANCE.LIST,
  PERMISSIONS.DASHBOARD.ANY,
  PERMISSIONS.MACHINE_INSTANCE.READ,
  PERMISSIONS.MACHINE_INSTANCE.LIST,
  PERMISSIONS.QRCODE.ANY,
  PERMISSIONS.MEDIA.ANY,
  PERMISSIONS.MEDIA_TYPE.ANY,
  PERMISSIONS.MEDIA_VERSION.ANY,
  PERMISSIONS.SERVICE_LEVEL_AGREEMENT.READ,
  PERMISSIONS.SERVICE_LEVEL_AGREEMENT.LIST,
  PERMISSIONS.TASK.READ,
  PERMISSIONS.TASK.LIST,
  PERMISSIONS.TASK.CREATE,
  PERMISSIONS.TASK.ASSIGN_USER,
  PERMISSIONS.TASK.CHANGE_STATUS,
  PERMISSIONS.TASK.PLAN,
  PERMISSIONS.TO_DO_LIST.READ,
  PERMISSIONS.TO_DO_LIST.LIST,
  PERMISSIONS.TO_DO_LIST_ITEM.READ,
  PERMISSIONS.TO_DO_LIST_ITEM.LIST,
  PERMISSIONS.TO_DO_LIST_ITEM.CHECK_UPDATE,
  PERMISSIONS.USER.READ,
  PERMISSIONS.USER.LIST,
  PERMISSIONS.WORK_PROTOCOL.READ,
  PERMISSIONS.WORK_PROTOCOL.LIST,
  PERMISSIONS.WORK_PROTOCOL.UPDATE,
  PERMISSIONS.CONTACT.READ,
  PERMISSIONS.CONTACT.LIST,
  PERMISSIONS.ENTITY_ACCESS.BY_COMPANY_UNIT,
  PERMISSIONS.SERVICE_LOG.READ,
]

export const Technician = [
  PERMISSIONS.BACKLOG.ANY,
  PERMISSIONS.COMPANY.READ,
  PERMISSIONS.COMPANY.LIST,
  PERMISSIONS.COMPANY_UNIT.READ,
  PERMISSIONS.COMPANY_UNIT.LIST,
  PERMISSIONS.COMPONENT_INSTANCE.READ,
  PERMISSIONS.COMPONENT_INSTANCE.LIST,
  PERMISSIONS.MACHINE_INSTANCE.READ,
  PERMISSIONS.MACHINE_INSTANCE.LIST,
  PERMISSIONS.QRCODE.ANY,
  PERMISSIONS.MEDIA.READ,
  PERMISSIONS.MEDIA.LIST,
  PERMISSIONS.MEDIA.CREATE,
  PERMISSIONS.MEDIA.UPDATE,
  PERMISSIONS.MEDIA_TYPE.AVATAR,
  PERMISSIONS.MEDIA_TYPE.GENERAL,
  PERMISSIONS.MEDIA_VERSION.READ,
  PERMISSIONS.MEDIA_VERSION.LIST,
  PERMISSIONS.MEDIA_VERSION.CREATE,
  PERMISSIONS.MEDIA_VERSION.UPDATE,
  PERMISSIONS.TASK.READ,
  PERMISSIONS.TASK.LIST,
  PERMISSIONS.TASK.CHANGE_STATUS,
  PERMISSIONS.TASK.CREATE,
  PERMISSIONS.TO_DO_LIST.READ,
  PERMISSIONS.TO_DO_LIST.LIST,
  PERMISSIONS.TO_DO_LIST_ITEM.READ,
  PERMISSIONS.TO_DO_LIST_ITEM.LIST,
  PERMISSIONS.TO_DO_LIST_ITEM.CHECK_UPDATE,
  PERMISSIONS.USER.READ,
  PERMISSIONS.WORK_PROTOCOL.READ,
  PERMISSIONS.WORK_PROTOCOL.LIST,
  PERMISSIONS.WORK_PROTOCOL.UPDATE,
  PERMISSIONS.CONTACT.READ,
  PERMISSIONS.CONTACT.LIST,
  PERMISSIONS.ENTITY_ACCESS.NO_LIMIT,
  PERMISSIONS.SERVICE_LOG.READ,
  PERMISSIONS.SERVICE_LEVEL_AGREEMENT.LIST,
]

export const roles: Record<UserGroups, Permission[]> = {
  ProviderSuperAdmin,
  ProviderAdmin,
  ClientSuperAdmin,
  ClientUnitAdmin,
  Technician,
}

type RecursiveValues<T> = {
  [Prop in keyof T]: T[Prop] extends Record<string, unknown>
    ? RecursiveValues<T[Prop]>
    : T[Prop]
}[keyof T]

export type Permission = RecursiveValues<typeof PERMISSIONS>

export const permissions = Object.values(PERMISSIONS).reduce<Permission[]>(
  (acc, curr) => [...acc, ...Object.values(curr)],
  []
)

export const getPermissions = (role: UserGroups) => roles[role] ?? []

export const hasPermission = (
  role: UserGroups,
  requiredPermission: Permission,
  userAttachedPermissions?: Permission[]
) => {
  if (userAttachedPermissions === undefined) {
    userAttachedPermissions = getPermissions(role)
  }

  if (userAttachedPermissions.includes(PERMISSIONS.ALL.ANY)) {
    return true
  }

  if (userAttachedPermissions.includes(requiredPermission)) {
    return true
  }

  if (
    userAttachedPermissions.includes(
      (requiredPermission.split('.')[0] + '.*') as Permission
    )
  ) {
    return true
  }

  if (
    userAttachedPermissions.includes(
      ('*.' + requiredPermission.split('.')[1]) as Permission
    )
  ) {
    return true
  }

  throw new AppError(
    `Permission denied - ${requiredPermission} is required`,
    HttpStatusCode.FORBIDDEN
  )
}

export const safeHasPermission = (
  role: UserGroups,
  permission: Permission,
  permissions?: Permission[]
) => {
  try {
    return hasPermission(role, permission, permissions)
  } catch {
    return false
  }
}

type Table = {
  companyId?: PgColumn
  companyUnitId?: PgColumn
  id: PgColumn
}

export const drizzleEntityAccess = (
  user: User,
  table: Table,
  userColumn?: keyof User
) => {
  const permissions = getPermissions(user.group as UserGroups)

  if (
    permissions.includes(PERMISSIONS.ENTITY_ACCESS.NO_LIMIT) ||
    permissions.includes(PERMISSIONS.ALL.ANY)
  ) {
    return undefined
  }

  if (
    permissions.includes(PERMISSIONS.ENTITY_ACCESS.BY_COMPANY) &&
    table.companyId
  ) {
    return eq(table.companyId, user.companyId)
  }

  if (
    permissions.includes(PERMISSIONS.ENTITY_ACCESS.BY_COMPANY_UNIT) &&
    table.companyUnitId
  ) {
    return eq(table.companyUnitId, user.companyUnitId)
  }

  if (
    permissions.includes(PERMISSIONS.ENTITY_ACCESS.BY_COMPANY_UNIT) &&
    userColumn === 'companyUnitId'
  ) {
    return eq(table.id, user.companyUnitId)
  }

  if (
    permissions.includes(PERMISSIONS.ENTITY_ACCESS.BY_COMPANY_UNIT) &&
    userColumn === 'companyId'
  ) {
    return eq(table.id, user.companyId)
  }

  if (
    permissions.includes(PERMISSIONS.ENTITY_ACCESS.BY_COMPANY) &&
    userColumn === 'companyId'
  ) {
    return eq(table.id, user.companyId)
  }

  if (permissions.includes(PERMISSIONS.ENTITY_ACCESS.BOTH) && userColumn) {
    if (userColumn === 'companyId') {
      if (table.companyUnitId) {
        return or(
          eq(table.id, user.companyId),
          eq(table.companyUnitId, user.companyUnitId)
        )
      }
      return or(eq(table.id, user.companyId), eq(table.id, user.companyUnitId))
    }

    if (userColumn === 'companyUnitId') {
      if (table.companyId) {
        return or(
          eq(table.id, user.companyUnitId),
          eq(table.companyId, user.companyId)
        )
      }
      return or(eq(table.id, user.companyUnitId), eq(table.id, user.companyId))
    }
  }

  if (permissions.includes(PERMISSIONS.ENTITY_ACCESS.BOTH)) {
    if (table.companyId && table.companyUnitId) {
      return or(
        eq(table.companyId, user.companyId),
        eq(table.companyUnitId, user.companyUnitId)
      )
    }

    if (table.companyId) {
      return eq(table.companyId, user.companyId)
    }

    if (table.companyUnitId) {
      return eq(table.companyUnitId, user.companyUnitId)
    }
  }

  if (!userColumn && (!table.companyId || !table.companyUnitId)) {
    return undefined
  }

  throw new AppError('Permission denied', HttpStatusCode.FORBIDDEN)
}

/**
 * If user has permission of type ANY, do not restrict access
 *
 * Specific media type access is defined by permissions
 *
 * If no permissions regarding media type are specified, throw an error signaling incorrect permission setup
 * @param user User object
 * @returns SQL query
 */
export const drizzleMediaTypeAccess = (user: User) => {
  const permissions = getPermissions(user.group as UserGroups)
  const queries = []

  if (
    permissions.includes(PERMISSIONS.ALL.ANY) ||
    permissions.includes(PERMISSIONS.MEDIA.ANY)
  ) {
    return undefined
  }

  if (permissions.includes(PERMISSIONS.MEDIA_TYPE.AVATAR)) {
    queries.push(eq(media.mediaType, 'avatar'))
  }

  if (permissions.includes(PERMISSIONS.MEDIA_TYPE.GENERAL)) {
    queries.push(eq(media.mediaType, 'general'))
  }

  if (permissions.includes(PERMISSIONS.MEDIA_TYPE.CONTRACT)) {
    queries.push(eq(media.mediaType, 'agreement'))
  }

  if (queries.length) {
    return or(...queries)
  }

  throw new AppError('Permission denied', HttpStatusCode.FORBIDDEN)
}

/**
 * If user has some entity access defined (is not undefined), return with the entity specific media also media with public access
 *
 * No entity access defined means user has access to all media
 * @param user User object
 * @returns SQL query
 */
export const drizzleMediaEntityAccess = (user: User) => {
  const entityAccess = drizzleEntityAccessMiddleware({
    user,
    table: media,
  })

  if (entityAccess) return or(eq(media.accessLevel, 'public'), entityAccess)

  return entityAccess
}

/**
 * Return media access query based on entity access and media type access
 * @param user User object
 * @returns SQL query
 */
export const drizzleMediaAccess = (user: User) => {
  return or(drizzleMediaEntityAccess(user), drizzleMediaTypeAccess(user))
}

export const drizzleEntityAccessMiddleware = (
  params: { user: User; table: Table; userColumn?: keyof User },
  fn?: () => SQL | undefined
) => {
  if (!fn) {
    return drizzleEntityAccess(params.user, params.table, params.userColumn)
  }

  return and(
    drizzleEntityAccess(params.user, params.table, params.userColumn),
    fn()
  )
}
