import { PERMISSIONS } from '@aeqoom/permissions'
import { createUniqueKey } from '@aeqoom/utils'
import CustomDataGrid from '@app/components/base/datagrid/CustomDataGrid'
import ComponentInstanceDialog from '@app/components/base/dialogs/_detailViewDialogs/ComponentInstanceDialog'
import CreateComponentInstanceDialogContent from '@app/components/base/dialogs/createForms/CreateComponentInstanceDialogContent'
import DeleteConfirmDialog from '@app/components/base/dialogs/DeleteConfirmDialog'
import { useDeleteAction } from '@app/hooks/useDeleteAction'
import {
  useDeleteComponentInstance,
  useGetComponentInstances,
} from '@app/queries/useComponentInstances'
import useUserStore from '@app/src/stores/useUserStore'
import getDeleteActionCol from '@app/src/utils/getDeleteActionCol'
import { useDialogStore } from '@app/stores/useDialogStore'
import { BiotechOutlined } from '@mui/icons-material'

import componentInstancesDataCol from './col'

const ComponentInstances = () => {
  const { data, isPending } = useGetComponentInstances()

  const mutation = useDeleteComponentInstance()

  const { openDialog } = useDialogStore()

  const {
    handleOpen,
    handleClose,
    handleConfirmDelete,
    isOpen,
    setSelectedItemId,
  } = useDeleteAction(mutation)

  const deleteActionCol = getDeleteActionCol({ handleOpen, setSelectedItemId })

  const { hasPermission } = useUserStore()

  const columns = [
    ...componentInstancesDataCol,
    ...(hasPermission(PERMISSIONS.COMPONENT_INSTANCE.DELETE)
      ? [deleteActionCol]
      : []),
  ]

  return (
    <>
      <DeleteConfirmDialog
        isPending={mutation.isPending}
        onClose={handleClose}
        onConfirm={handleConfirmDelete}
        isOpen={isOpen}
      />
      <CustomDataGrid
        addClickOptions={{
          Dialog: CreateComponentInstanceDialogContent,
          title: 'Create component instance',
          options: {
            icon: BiotechOutlined,
            isUnique: false,
          },
        }}
        onRowClick={({ row }) => {
          openDialog(<ComponentInstanceDialog id={row.id} />, row.name, {
            uniqueKey: createUniqueKey(['componentInstance', row.id]),
          })
        }}
        disableCreateNew={!hasPermission(PERMISSIONS.COMPONENT_INSTANCE.CREATE)}
        loading={isPending}
        pageLabel="Component instances"
        rows={data || []}
        columns={columns}
      />
    </>
  )
}

export default ComponentInstances
