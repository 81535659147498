import {
  AddSLARequest,
  EditSLARequest,
  GenerateTasksRequest,
  GetSLAsRequest,
} from '@aeqoom/contracts'
import { PERMISSIONS } from '@aeqoom/permissions'
import { SLA_KEYS } from '@aeqoom/types'
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import toast from 'react-hot-toast'

import useUserStore from '../stores/useUserStore'
import { client } from './client'
import parseIncoming from './parseIncoming'

const invalidateMediaQueries = (queryClient: QueryClient) => {
  const allKeys: string[] = Object.values(SLA_KEYS).flat()
  queryClient.invalidateQueries({
    predicate: (query) => allKeys.includes(query.queryKey[0] + ''),
  })
}

export const useGetSlas = ({
  query,
}: { query?: GetSLAsRequest['query'] } = {}) => {
  const { hasPermission } = useUserStore()

  return useQuery({
    enabled: hasPermission(PERMISSIONS.SERVICE_LEVEL_AGREEMENT.LIST),
    queryKey: [SLA_KEYS.getSlas, query],
    queryFn: () =>
      client.serviceLevelAgreements
        .getServiceLevelAgreements({ query })
        .then(parseIncoming),
  })
}

export const useCreateSla = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: (body) => {
      queryClient.invalidateQueries()
      toast.success(body.message)
    },
    mutationFn: (body: AddSLARequest['body']) => {
      return client.serviceLevelAgreements
        .addServiceLevelAgreement({ body })
        .then(parseIncoming)
    },
  })
}

export const useEditSla = () => {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: (body) => {
      queryClient.invalidateQueries()
      toast.success(body.message)
    },
    mutationFn: ({
      body,
      params,
    }: {
      body: EditSLARequest['body']
      params: EditSLARequest['params']
    }) =>
      client.serviceLevelAgreements
        .editServiceLevelAgreement({ body, params })
        .then(parseIncoming),
  })
}

export const useGetSla = ({
  id,
  enabled = !!id,
}: {
  id?: number
  enabled?: boolean
}) => {
  const { hasPermission } = useUserStore()

  return useQuery({
    enabled: enabled && hasPermission(PERMISSIONS.SERVICE_LEVEL_AGREEMENT.READ),
    queryKey: [SLA_KEYS.getSla, id],
    queryFn: () =>
      client.serviceLevelAgreements
        .getServiceLevelAgreement({
          params: { id: id! },
        })
        .then(parseIncoming),
  })
}

export const useGenerateTasks = () => {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: (body) => {
      queryClient.invalidateQueries()
      toast.success(body.message)
    },
    mutationFn: ({ params }: { params: GenerateTasksRequest['params'] }) =>
      client.serviceLevelAgreements
        .generateTasks({ params })
        .then(parseIncoming),
  })
}
