import { useDropzoneValidation } from '@aeqoom/hooks'
import { Upload } from '@mui/icons-material'
import { Backdrop, Stack } from '@mui/material'
import { ReactElement } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import toast from 'react-hot-toast'

type Props = {
  children: ReactElement[]
  launchAction: (files: File[]) => void
  maxSize?: number
  multiple?: boolean
}

const BackdropUpload = ({
  children,
  launchAction,
  maxSize = 50000000,
  multiple = true,
}: Props) => {
  const { getValidationMessage } = useDropzoneValidation()

  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    const fileRejection = fileRejections[0]

    if (fileRejection) {
      for (const error of fileRejection.errors) {
        const validationMessage = getValidationMessage(error.code, {
          maxSize,
          multiple,
        })

        toast.error(validationMessage)
      }
    }

    if (acceptedFiles.length > 0) launchAction(acceptedFiles)
  }

  const { getRootProps, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
    multiple,
    maxSize,
  })

  return (
    <Stack {...getRootProps()} sx={{ height: '100%' }}>
      <Backdrop open={isDragActive} sx={{ zIndex: 10 }}>
        <Upload sx={{ fontSize: 72, color: '#fff' }} />
      </Backdrop>
      {children}
    </Stack>
  )
}

export default BackdropUpload
